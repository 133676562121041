import React from "react"

export default function Support() {
  return (
    <div className="container">
      <div className="row">
        <div className="col text-center">
          <h1 className="mt-3">Gift Exchange Generator Support</h1>

          <p>
            Please email any support-related concerns to jwvansickle@icloud.com
          </p>
        </div>
      </div>
    </div>
  )
}
